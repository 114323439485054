import React from 'react';
import './index.less';

export default () => (
  <div className="slide2-wrapper MobileStewardSlide1">
    <div className="logo" />
    <div className="slide2-text-wrapper">
      <span className="silde2-title">专业游戏管家团队</span>
      <span className="slide2-text">您的所有问题 我都会耐心倾听</span>
    </div>
  </div>
);
