import React from 'react';
import logo from '@/images/logo.png';
import { useMouseMove } from '@/utils';
import './index.less';

export default () => {
  const dis = useMouseMove();
  return (
    <div className="slide1 slide">
      <img className="slide1-logo" src={logo} alt="" />
      <img
        className="slide-fragment slide-fragment1"
        style={{ transform: `translateX(${dis * 30}%)` }}
        src={require('@/images/slide1-fragment1.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment2"
        style={{ transform: `translateX(${20 - dis * 30}%)` }}
        src={require('@/images/slide1-fragment1.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment3"
        style={{ transform: `translateX(${20 - dis * 30}%)` }}
        src={require('@/images/slide1-fragment2.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment4"
        style={{ transform: `translateX(${dis * 30}%)` }}
        src={require('@/images/slide1-fragment3.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment5"
        style={{ transform: `translateX(${20 - dis * 30}%)` }}
        src={require('@/images/slide1-fragment4.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment6"
        style={{ transform: `translateX(${dis * 30}%)` }}
        src={require('@/images/slide1-fragment4.png')}
        alt=""
      />
      <img
        className="slide-fragment slide-fragment7"
        style={{ transform: `translateX(${dis * 30}%)` }}
        src={require('@/images/slide1-fragment4.png')}
        alt=""
      />
    </div>
  );
};
