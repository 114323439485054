import React, { useEffect, useState, useCallback } from 'react';
// 判断移动端
function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  const reg = /(ipad|ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i;
  const mobile = userAgent.match(reg);
  if (mobile) {
    return true;
  }
  return false;
}

const getDeviceType = () => {
  const height = document.documentElement.clientHeight;
  const width = document.documentElement.clientWidth;
  if (isMobile()) {
    return height / width > 1 ? 'mobile' : 'mobile-horizontal';
  }
  return 'pc';
};
export const useDeviceType = () => {
  const [type, setType] = useState(getDeviceType());
  const onResize = useCallback(() => {
    setType(getDeviceType());
  }, []);
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });
  return type;
};

export const useMouseMove = () => {
  const [disPer, setDisPer] = useState(1);

  const onMouseMove = useCallback(
    e => setDisPer(e.pageX / window.innerWidth),
    [],
  );

  useEffect(() => {
    window.addEventListener('mouseover', onMouseMove);
    return () => window.removeEventListener('mouseover', onMouseMove);
  }, [onMouseMove]);
  return disPer;
};

export const UrlGovImg = () => {
  const webprefix = 'https://szcert.ebs.org.cn/';
  const id = '1efd63b4-7863-490a-839f-53968220109f';
  const imgUrl = 'https://szcert.ebs.org.cn/Images/govIcon.gif';
  return (
    <a href={webprefix + id}>
      <img
        src={imgUrl}
        title="深圳市市场监督管理局企业主体身份公示"
        alt="深圳市市场监督管理局企业主体身份公示"
        border="0"
      />
    </a>
  );
};

/**
 * @description 查询url参数
 * @param key 查询参数key值
 * @returns key传入字符串返回相应值，key传入数组则放回包含所有key数组项内值的参数值的对象
 */
export const getUrlParams = (key) => {
  let { search } = window.location;
  if (window.location.hash) {
    search = `?${window.location.hash.split('?')[1]}`;
  }
  const searchParams = new URLSearchParams(decodeURI(search));
  const readKeys = key || searchParams.keys();
  if (typeof(readKeys) === 'string') {
    return searchParams.get(readKeys);
  }
  const paramsObj = {};
  for (const keyStr of readKeys) {
    paramsObj[keyStr] = searchParams.get(keyStr);
  }
  return paramsObj;
};
