import React, { useState } from 'react';
import './index.less';
import { useDialog } from '@/components/Dialog';
import tab1 from '@/images/v-tab1.png';
import tab2 from '@/images/v-tab2.png';
import { Link } from 'react-router-dom';
import { getUrlParams } from '@/utils';

export default function () {
  const [showDialog, Dialog] = useDialog();
  const [index, setIndex] = useState(1);
  const hasBack = getUrlParams('hasBack');
  return (
    <div className="v-whole">
      <div className="head">
        {!hasBack
          ? <Link to="/">
            <div className="back">返回首页</div>
          </Link>
          : <div className="back-btn" onClick={() => {
            window.history.back();
          }}>返回</div>
        }
      </div>

      <div className="main">
        <div className="main-head">
          <div
            className={index === 1 ? 'select' : ''}
            role="button"
            onClick={() => setIndex(1)}
          >
            关于超核玩家
          </div>
          <div
            className={index === 2 ? 'select' : ''}
            role="button"
            onClick={() => setIndex(2)}
          >
            仿冒案例
          </div>
        </div>
        {index === 1 && (
          <div className="tab-1">
            <img className="home-logo" src={tab1} alt="" />
          </div>
        )}
        {index === 2 && (
          <div className="tab-2">
            <img className="home-logo" src={tab2} alt="" />
          </div>
        )}
      </div>

      <div className="foot">
        <div className="foot-head" />
        <div className="slide4-business-text">
          <p>
            <a href="https://ieg.tencent.com/"> 腾讯互娱 </a>
            {' '}
            /
            {' '}
            <a href="https://careers.tencent.com/"> 加入我们 </a>
            {' '}
            /
            {' '}
            <a role="button" onClick={showDialog}>
              商务合作
            </a>
            /
            {' '}
            <a role="button" onClick={showDialog}>
              联系我们
            </a>
            /
            {' '}
            <a href="https://game.qq.com/contract_software.shtml"> 用户协议 </a>
            /
            <a href="https://privacy.qq.com/"> 隐私政策 </a>
            {' '}
            /
            {' '}
            <a href="https://privacy.qq.com/privacy-children.htm">
              儿童隐私保护声明
            </a>
          </p>
          <p>
            COPYRIGHT © 1998 – 2020 TENCENT. ALL RIGHTS RESERVED. 腾讯公司
            版权所有
          </p>
        </div>
        <Dialog />
      </div>
    </div>
  );
}
