import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMouseMove } from '@/utils';

import './index.less';
import { Link } from 'react-router-dom';

const sologn = [
  '您的所有问题 我都会耐心倾听',
  '玩游戏 我们也是专业的',
  '认真工作 才是我们的日常',
];
export default ({ type }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dis = useMouseMove();
  const swiperRef = useRef(null);
  return (
    <div className="slide2-wrapper">
      <Swiper
        className="slide2-swiper"
        spaceBetween={50}
        ref={swiperRef}
        onSlideChange={e => setActiveIndex(e.activeIndex)}
      >
        <SwiperSlide>
          <div className="slide2-subslide1 slide">
            <div className="slide2-housekeeper-wrapper">
              <img
                className="slide2-joy-butlers"
                src={require('@/images/joy-butlers.png')}
                alt=""
              />
              <div className="slide2-housekeeper-list">
                <img
                  className="slide2-housekeeper"
                  src={require('@/images/housekeeper1.png')}
                  alt=""
                />
                <img
                  className="slide2-housekeeper"
                  src={require('@/images/housekeeper2.png')}
                  alt=""
                />
                <img
                  className="slide2-housekeeper"
                  src={require('@/images/housekeeper3.png')}
                  alt=""
                />
                <img
                  className="slide2-housekeeper"
                  src={require('@/images/housekeeper4.png')}
                  alt=""
                />
                <img
                  className="slide2-housekeeper"
                  src={require('@/images/housekeeper5.png')}
                  alt=""
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide2-subslide2" />
          <div className="slide2-subslide2-blank" />
          <div
            className="slide2-subslide2-person"
            style={{ transform: `translateX(${dis * 6 - 3}%)` }}
          />
          <div className="slide2-subslide2-cube" />
          <div className="slide2-subslide2-round" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide2-subslide3" />
          <div className="slide2-sub3-v" />
          <div
            className="slide2-subslide3-person"
            style={{ transform: `translateX(${dis * 6 - 3}%)` }}
          />
        </SwiperSlide>
      </Swiper>
      <div className="slide2-text-wrapper">
        <button
          type="button"
          className="pre-slide"
          aria-label="Pre"
          disabled={activeIndex === 0 ? '' : null}
          onClick={() => swiperRef.current.swiper.slidePrev()}
        />
        <button
          type="button"
          className="next-slide"
          aria-label="Next"
          disabled={activeIndex === 2 ? '' : null}
          onClick={() => swiperRef.current.swiper.slideNext()}
        />
        <span className="slide2-text">{sologn[activeIndex]}</span>
        <span className="silde2-bg-text" />
      </div>
      {type === 'pc' && (
        <Link to="/aboutme">
          <button
            type="button"
            aria-label="About us"
            className="slide2-about-us"
          >
            <img src={require('@/images/aboutus.svg')} alt="" />
            <span>关于我们</span>
          </button>
        </Link>
      )}
    </div>
  );
};
