import React, { useCallback, useState } from 'react';
import './index.less';
import { useDeviceType } from '@/utils';
import { Model } from './model';

export const useDialog = () => {
  const [show, setShow] = useState(false);
  const type = useDeviceType();
  const showDialog = useCallback(() => {
    setShow(true);
  }, []);
  const Dialog = useCallback(
    () => show && (
    <Model>
      <div
        className={`official-dialog-pc ${
          type === 'mobile' ? 'official-dialog-mobile' : ''
        }`}
      >
        <h3>关于我们</h3>
        <div className="official-dialog-text">
          <p>成立于2017年11月。</p>
          <p>
            我们通过成熟完善的管理体系及后台工具，以“游戏管家”为角色，为腾讯游戏核心玩家提供高质量够专业有温度的服务。
          </p>
          <p style={{ marginTop: '1.5em' }}>
            同时，配合各个腾讯游戏在运营过程中关键节点，提供专属订制的运营活动、福利、资讯等内容，促进玩家与游戏之间更亲密的关系，双向提供全方位的支持。
          </p>
          <p style={{ marginTop: '1.5em' }}>
            结合游戏品牌和内容渠道，我们也具备多渠道的内容传播能力，为广大游戏玩家和游戏产品提供更直接，更有效的沟通渠道，传递玩家心声，了解游戏痛点，为腾讯游戏的健康生态提供更有力的保障。
          </p>
          <p style={{ marginTop: '1.5em' }}>
            超核玩家官方邮箱：SuperCorePlayer@tencent.com
          </p>
        </div>
        <button
          type="button"
          aria-label="close"
          className="official-close"
          onClick={() => setShow(false)}
        />
      </div>
    </Model>
    ),
    [show],
  );
  return [showDialog, Dialog];
};
