import React, { useRef, useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { useDialog } from '@/components/Dialog/index';
import './index.less';

SwiperCore.use([EffectFade]);
export default function () {
  const dom = document.documentElement;
  const rem = (dom.clientWidth / 750) * 100;
  const sliderHeight = dom.clientHeight - 5.1 * rem;
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const setSlideHidden = useCallback(
    index => activeIndex !== index,
    [activeIndex],
  );
  const [showDialog, Dialog] = useDialog();

  return (
    <div className="slide4 slide">
      <div className="slide4-subslide">
        <Swiper
          spaceBetween={0}
          ref={swiperRef}
          onSlideChange={e => setActiveIndex(e.activeIndex)}
        >
          <SwiperSlide style={{ visibility: setSlideHidden(0) && 'hidden' }}>
            <div
              className="slide4-subslide1"
              style={{ width: 0.889 * sliderHeight, height: sliderHeight }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="slide4-text-wrapper">
        <img className="mini-program" src="https://static.svip.game.qq.com/2af31b02d453f4adaa13965ff4ef83c5.png" alt="" />
        <div className="slide4-text">
          <p className="slide4-text-title">超核小站</p>
          <p className="slide4-text-deatail">
            超核小站是超核玩家专属一站式集合平台。<br />
            在这里，能快速抵达各类超核玩家专属活动、获取最新超核资讯、更能便捷领取各种专属福利，助力于超核玩家在游戏内外的超然体验。
          </p>
        </div>
      </div>
      <div className="slide4-footer">
        <div className="company-detail">
          <div className="steward">
            全国官方客服
            {' '}
            <span>9509-2888</span>
            {' '}
            7*24小时
          </div>
          <div className="business">
            <a href="https://ieg.tencent.com/"> 腾讯互娱 </a>
            {' '}
            /
            {' '}
            <button type="button" onClick={showDialog}>
              联系我们
            </button>
            {' '}
            /
            {' '}
            <a href="https://game.qq.com/contract_software.shtml"> 用户协议 </a>
            /
            <a href="https://privacy.qq.com/"> 隐私政策 </a>
            {' '}
            /
            {' '}
            <a href="https://privacy.qq.com/privacy-children.htm">
              儿童隐私保护声明
            </a>
          </div>
          <div className="copyright">
            COPYRIGHT © 1998 – 2020 TENCENT. ALL RIGHTS RESERVED.
            {' '}
          </div>
        </div>
      </div>
      <Dialog />
    </div>
  );
}
