import React from 'react';
import hat from '@/images/slide3-hat.png';
import rectIcon from '@/images/slide3-rect.svg';
import './index.less';

export default ({ type }) => (
  <div className="slide3 slide">
    <img className="slide3-hat" src={hat} alt="" />
    <div className="slide3-text">
      <div className="slide3-text-list">
        <img className="slide3-text-title-image" src={rectIcon} alt="" />
        <div className="slide3-text-title">
          <p>
            管家贴心服务
            {type === 'pc' && <br />}
            <span>7*24小时微信随时随地的朋友式陪伴</span>
          </p>
          <p className="slide3-text-bottom">
            <img src={require('@/images/slide3-text-img1.png')} alt="" />
            24h在线 /
            <img src={require('@/images/slide3-text-img2.png')} alt="" />
            优先处理 /
            <img src={require('@/images/slide3-text-img3.png')} alt="" />
            策划连线
          </p>
        </div>
      </div>
      <div className="slide3-text-list">
        <img className="slide3-text-title-image" src={rectIcon} alt="" />
        <div className="slide3-text-title">
          <p>
            极佳游戏体验
            {' '}
            {type === 'pc' && <br />}
            {' '}
            <span>第一手最新资料</span>
          </p>
          <p className="slide3-text-bottom">
            <img src={require('@/images/slide3-text-img4.png')} alt="" />
            定制攻略 /
            <img src={require('@/images/slide3-text-img5.png')} alt="" />
            独家资讯 /
            <img src={require('@/images/slide3-text-img6.png')} alt="" />
            抢先体验
          </p>
        </div>
      </div>
      <div className="slide3-text-list">
        <img className="slide3-text-title-image" src={rectIcon} alt="" />
        <div className="slide3-text-title">
          <p>
            专属活动礼包
            {type === 'pc' && <br />}
            {' '}
            <span>专属活动刺激登榜</span>
          </p>
          <p className="slide3-text-bottom">
            <img src={require('@/images/slide3-text-img7.png')} alt="" />
            专属外显 /
            <img src={require('@/images/slide3-text-img8.png')} alt="" />
            限量福利 /
            <img src={require('@/images/slide3-text-img9.png')} alt="" />
            超核聚会
          </p>
        </div>
      </div>
    </div>
  </div>
);
