import React from 'react';
import PropTypes from 'prop-types';
import './index.less';
const Step = React.memo(({
  step, totalStep, className = '', onClick,
}) => (
  <nav className={`${className} step-list`}>
    {totalStep.map((item, index) => (
      <button
        type="button"
        className={`step-list-item ${
          step === index ? 'step-list-item-active' : ''
        }`}
        key={item}
        onClick={() => onClick(index)}
        aria-label={item}
      />
    ))}
  </nav>
));
Step.propTypes = {
  step: PropTypes.number.isRequired,
  totalStep: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
Step.defaultProps = {
  className: '',
  totalStep: [],
  onClick: () => {},
};
export default Step;
