import React, { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import './index.less';

export function Model({ children }) {
  const rootElemRef = React.useRef(document.createElement('div'));
  rootElemRef.current.className = 'official-model';
  useLayoutEffect(() => {
    // Look for existing target dom element to append to
    const parentElem = document.getElementById('root');
    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current);
    // This function is run on unmount
    return function removeElement() {
      parentElem.removeChild(rootElemRef.current);
    };
  }, []);
  return createPortal(children, rootElemRef.current);
}
