import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import './index.css';
import Slide1 from '@/components/PC/Slide1';
import Slide2 from '@/components/PC/Slide2';
import Slide3 from '@/components/PC/Slide3';
import Slide4 from '@/components/PC/Slide4';
import MobileSlide1 from '@/components/Mobile/Slide1';
import MobileStewardSlide1 from '@/components/Mobile/StewardSlide1';
import MobileStewardSlide2 from '@/components/Mobile/StewardSlide2';
import MobileStewardSlide3 from '@/components/Mobile/StewardSlide3';
import MobileSlide3 from '@/components/Mobile/Slide3';
import MobileSlide4 from '@/components/Mobile/Slide4';
import Step from '@/components/Step';
import logo from '@/images/host-logo.png';
import { useDeviceType } from '@/utils';
import { Link } from 'react-router-dom';

SwiperCore.use([Mousewheel]);
const stepArr = ['Home', 'About', 'Services', 'Mini-Program'];
const classList = {
  pc: 'pc',
  mobile: 'mobile',
  'mobile-horizontal': 'pc mobile-horizontal',
};
export default function () {
  const type = useDeviceType();
  const [active, setActive] = useState(0);
  const [validate, setValidate] = useState(false);
  const swiperRef = useRef();
  useEffect(() => {
    const openid = localStorage.getItem('openid');
    if (openid) {
      setValidate(false);
    } else {
      localStorage.setItem('openid', 'supercore');
      setValidate(true);
    }
  }, []);
  const onClick = useCallback((index) => {
    swiperRef.current.swiper.slideTo(index);
  }, []);
  console.log(active);
  return (
    <div className={`App ${classList[type]}`}>
      {type === 'pc' && (
        <>
          <Step
            className="home-step"
            step={active}
            totalStep={stepArr}
            onClick={onClick}
          />
          <img className="home-logo" src={logo} alt="" />
          <button
            type="button"
            className="home-pre-slide"
            aria-label="Pre"
            disabled={active === 0 ? 'disabled' : null}
            onClick={() => {
              swiperRef.current.swiper.slidePrev();
            }}
          />
          <button
            type="button"
            className="home-next-slide"
            aria-label="Next"
            disabled={active === 3 ? 'disabled' : null}
            onClick={() => swiperRef.current.swiper.slideNext()}
          />
        </>
      )}
      <SlideList
        ref={swiperRef}
        type={type}
        onSlideChange={e => setActive(e.activeIndex)}
      />
      {validate && (
        <div className={`modals ${classList[type]}`}>
          <div
            role="button"
            className="close"
            onClick={() => setValidate(false)}
          >
            关闭
          </div>
          <div className="rules">
            <p>
              腾讯游戏超核玩家仅以微信/企业微信/企业QQ的方式提供服务，请勿接受任何仿冒“超核”的非正规渠道服务或提供个人信息。
            </p>
            <Link to={type === 'pc' ? '/aboutme' : '/moveaboutme'}>
              <div className="button"> 查看详情 </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
const SlideList = React.forwardRef(({ onSlideChange, type }, ref) => (
  <Swiper
    ref={ref}
    className="home-swiper"
    direction="vertical"
    spaceBetween={50}
    onSlideChange={onSlideChange}
    mousewheel
  >
    <SwiperSlide>
      {type === 'mobile' ? <MobileSlide1 /> : <Slide1 />}
    </SwiperSlide>
    {type === 'mobile' ? (
      <>
        <SwiperSlide key="mobile-slide2-1">
          <MobileStewardSlide1 />
        </SwiperSlide>
        <SwiperSlide key="mobile-slide2-2">
          <MobileStewardSlide2 />
        </SwiperSlide>
        <SwiperSlide key="mobile-slide2-3">
          <MobileStewardSlide3 />
        </SwiperSlide>
      </>
    ) : (
      <SwiperSlide>
        <Slide2 type={type} />
      </SwiperSlide>
    )}
    <SwiperSlide>
      {type === 'mobile' ? <MobileSlide3 /> : <Slide3 type={type} />}
    </SwiperSlide>
    <SwiperSlide>
      {type === 'mobile' ? <MobileSlide4 /> : <Slide4 type={type} />}
    </SwiperSlide>
  </Swiper>
));

SlideList.propTypes = {
  onSlideChange: PropTypes.func,
};
SlideList.defaultProps = {
  onSlideChange: () => { },
};
