import Home from '@/components/Home';
import Aboutme from '@/components/aboutme';
import Moveaboutme from '@/components/v-aboutme';
import { useRoutes } from 'react-router-dom';

const ROUTER_COFNIG = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/aboutme',
    element: <Aboutme />,
  },
  {
    path: '/moveaboutme',
    element: <Moveaboutme />,
  },
];

const Routes = () => {
  const routing = useRoutes(ROUTER_COFNIG);
  return <>{routing}</>;
};

export default Routes;
