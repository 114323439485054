import React from 'react';
import './index.less';
import { Link } from 'react-router-dom';

export default () => (
  <div className="slide2-wrapper MobileStewardSlide3">
    <div className="logo" />
    <div className="slide2-text-wrapper">
      <span className="silde2-title">专业游戏管家团队</span>
      <span className="slide2-text">认真工作 才是我们的日常</span>
    </div>
    <Link to="/moveaboutme">
      <button type="button" className="aboutus" aria-label="aboutus" />
    </Link>
  </div>
);
