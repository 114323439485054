import React from 'react';
import './index.less';

export default () => (
  <div className="slide2-wrapper MobileStewardSlide2">
    <div className="logo" />
    <div className="slide2-text-wrapper">
      <span className="silde2-title">专业游戏管家团队</span>
      <span className="slide2-text">玩游戏 我们也是专业的</span>
    </div>
    <div className="slide2-subslide2-cube" />
    <div className="slide2-subslide2-round" />
  </div>
);
