import React, { useRef, useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { useDialog } from '@/components/Dialog/index';
import './index.less';

SwiperCore.use([EffectFade]);
export default function () {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const setSlideHidden = useCallback(
    index => activeIndex !== index,
    [activeIndex],
  );
  const [showDialog, Dialog] = useDialog();

  return (
    <div className="slide4 slide">
      <div className="slide4-text-wrapper">
        <p>超核小站</p>
        <p>
          超核小站是超核玩家专属一站式集合平台。<br/>
          在这里，能快速抵达各类超核玩家专属活动、获取最新超核资讯、更能便捷领取各种专属福利，助力于超核玩家在游戏内外的超然体验。
        </p>
        <div className="mini-program">
          <img src="https://static.svip.game.qq.com/2af31b02d453f4adaa13965ff4ef83c5.png" alt="" />
          <p>扫码前往超核小站</p>
        </div>
      </div>
      <div className="slide4-business">
        <div className="slide4-business-text">
          <p>全国官方客服 9509-2888</p>
          <p>服务时间 7*24小时</p>
          <p>
            <a href="https://ieg.tencent.com/"> 腾讯互娱 </a>
            {' '}
            /
            {' '}
            <a href="https://careers.tencent.com/"> 加入我们 </a>
            {' '}
            /
            {' '}
            <a role="button" onClick={showDialog}>
              商务合作
            </a>
            {' '}
            /
            {' '}
            <a role="button" onClick={showDialog}>
              联系我们
            </a>
            {' '}
            /
            {' '}
            <a href="https://game.qq.com/contract_software.shtml"> 用户协议 </a>
            /
            <a href="https://privacy.qq.com/"> 隐私政策 </a>
            {' '}
            /
            {' '}
            <a href="https://privacy.qq.com/privacy-children.htm">
              儿童隐私保护声明
            </a>
          </p>
          <p>
            COPYRIGHT © 1998 – 2020 TENCENT. ALL RIGHTS RESERVED. 腾讯公司
            版权所有
          </p>
        </div>
      </div>
      <div className="slide4-subslide">
        <Swiper
          allowTouchMove={false}
          spaceBetween={100}
          ref={swiperRef}
          onSlideChange={e => setActiveIndex(e.activeIndex)}
        >
          <SwiperSlide style={{ visibility: setSlideHidden(0) && 'hidden' }}>
            <div className="slide4-subslide1">
              <img
                src={require('../../../images/slide4-subslide1-logo1.png')}
                alt=""
                className="slide4-subslide1-logo1"
              />
              <img
                src={require('../../../images/slide4-subslide1-logo2.svg')}
                alt=""
                className="slide4-subslide1-logo2"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="slide2-text-wrapper slide4-text-wrapper2">
        <span className="silde2-bg-text silde4-bg-text" />
      </div>
      <Dialog />
    </div>
  );
}
